.App {
  text-align: center;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: normal;
  z-index: 2;
}

.App-logo {
  width: 100vw;
  max-width: 500px;
  pointer-events: none;
  margin-bottom: 20px;
}

.App-logo-intro {
  width: 100vw;
  max-width: 500px;
  pointer-events: none;
}

/*@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-home-header {
  height: 65%;
  padding-bottom: 30px;  
}

.App-intro-header {
  height: 35%;
}

.App-home-header, .App-intro-header {
  position: relative;
  z-index: 2;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-home-button {
  width: 300px;
  height: 80px;
  margin-top: 10px;
  font-weight: bold;
  background-color: #C4C4C4;
  border-color: #fff;
  border-width: 1px;
  border-radius: 5px;
  box-shadow: none;
  font-size: 14px;
  color: #000;
}

.App-home-button-link {
  text-decoration: none;
  color: black;
}

.App-intro-button-link {
  text-decoration: none;
  color: #1A79BD;
}

.App-home-buttons-div {
  height: 35%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*.App-intro-footer {
  z-index: 1;
  height: 35%;
  max-height: 300px;
  min-height: 260px;
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}*/

.Footer-img-div{
  position: fixed;
  bottom: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.Footer-logos-div{
  z-index: 999;
  height: 40px;
  position: fixed;
  bottom: 0px;
  display: inline-block;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
}

.App-modal-footer {
  z-index: 1;
  height: 35%;
  max-height: 300px;
  min-height: 260px;
  position: fixed;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.App-footer-img {
  width: 100%;
  max-width: 500px;
  pointer-events: none;
  z-index: 4;
}

.App-modal-img {
  width: 93%;
  max-width: 500px;
  pointer-events: none;
  z-index: 4;
}

.Logo-atelier {
  margin: 0 auto;
  width: 130px;
}

.Logo-deustotech {
  margin: 0 auto;
  width: 150px;
  padding-left: 20px;
  padding-bottom: 5px;
}

.Logo-atelier-div {
  width: 100%;
  background-color: #fff;
}

.Intro-text-div {
  z-index: 99;
  position: relative;
  background-color: #e6e6e6;
  color: black;
  width: 100vw;
  max-width: 500px;
  height: 48vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Intro-text {
  padding: 15px;
  font-size: 17px;
  line-height: 1.6;
  font-weight: 500;
  font-family: 'Roboto-Regular';
}

.Intro-text-left {
  padding: 15px;
  font-size: 17px;
  line-height: 1.6;
  font-weight: 500;
  font-family: 'Roboto-Regular';
  text-align: left;
}

.Intro-text-bullet-point {
  padding-left: 10px;
}

.Intro-button-left {
  position: absolute;
  left: 15px;
  bottom: 15px;
  font-size: 18px;
  font-family: 'Roboto-Regular';
  color: #1A79BD;
  border-width: 0px;
  background-color: transparent;
  font-weight: 400;
}

.Intro-button-right {
  position: absolute;
  right: 15px;
  bottom: 15px;
  font-size: 18px;
  font-family: 'Roboto-Regular';
  color: #1A79BD;
  border-width: 0px;
  background-color: transparent;
  font-weight: 400;
}

.Intro-question-icon-div {
  z-index: 100;
  position: absolute;
  bottom: 80px;
}

.Intro-question-icon {
  width: 120px;
}

.Intro-board-icon {
  width: 128px;
}

.Intro-board-icon-div {
  z-index: 100;
  position: absolute;
  bottom: 50px;
  right: 30px;
}

.Intro-thinking-icon {
  width: 118px;
}

.Intro-thinking-icon-div {
  z-index: 100;
  position: absolute;
  bottom: 55px;
  /*right: 0px;*/
}

.App-game-scope{
  width: 130px;
  height: 150px;
  position: relative;
}

.App-game-scope-title-top{
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  font-size: 14px;
  font-family: 'Roboto-Regular';
}

.App-game-scope-title-bottom{
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-size: 14px;
  font-family: 'Roboto-Regular';
}

.App-game-scope-level-left{
  height: 90px;
  position: absolute;
  left: 10px;
  top: 30px;
}

.App-game-scope-level-right{
  height: 90px;
  position: absolute;
  right: 10px;
  top: 30px;
}

.Scope-level-value-bar-left {
  position: absolute;
  left: 16px;
  bottom: 37px;
  width: 6px;
  height: 75px;
  border-radius: 10px;

  background-color: #C4C4C4;
  border-color: #fff;
}

.Scope-level-value-bar-right{
  position: absolute;
  right: 16px;
  bottom: 37px;
  width: 6px;
  height: 75px;
  border-radius: 10px;

  background-color: #C4C4C4;
  border-color: #fff;
}

.App-game-scope-icon-left{
  width: 70px;
  height: 70px;
  position: absolute;
  top: 40px;
  right: 20px;
}

.App-game-scope-icon-right{
  width: 70px;
  height: 70px;
  position: absolute;
  top: 40px;
  left: 20px;
}

.App-game-scope-value-left-top{
  position: absolute;
  bottom: 0px;
  left: 5px;
  font-weight: bold;
  font-size: 15px;
  font-family: 'Roboto-Regular';
}

.App-game-scope-value-right-top{
  position: absolute;
  bottom: 0px;
  right: 5px;
  font-weight: bold;
  font-size: 15px;
  font-family: 'Roboto-Regular';
}

.App-game-scope-value-left-bottom{
  position: absolute;
  top: 0px;
  left: 5px;
  font-weight: bold;
  font-size: 15px;
  font-family: 'Roboto-Regular';
}

.App-game-scope-value-right-bottom{
  position: absolute;
  top: 0px;
  right: 5px;
  font-weight: bold;
  font-size: 15px;
  font-family: 'Roboto-Regular';
}

.App-scopes-div {
  position: relative;
  width: 100vw;
  max-width: 500px;
  height: 400px;
  z-index: 5;
}

.Society-scope{
  position: absolute;
  left: 10px;
  top: 10px;
}

.Environment-scope {
  position: absolute;
  right: 10px;
  top: 10px;
}

.Economy-scope{
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.Innovation-scope{
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.Game-actions-label{
  font-size: 26px;
  font-weight: bold;
  font-family: 'FingerPaint-Regular';
}

.Game-actions-div{
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}

.Game-dilema-event-button{
  margin-top: 40px;
  font-size: 22px;
  font-weight: 500;
  font-family: 'Roboto-Regular';
  color: #0E3654;
  background-color: transparent;
  border-style: hidden;  
  z-index: 5;
}

.Game-dilema-event-link {
  text-decoration: none;
  color: #0E3654;
}

.Dilema-circle {
  position: absolute;
  top: 250px;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: #B7C0F1; 
  box-shadow: 0px 2px 0px rgba(0,0,0,0.3);
  border-style: hidden;
  align-items: center;
  z-index: 5;
}

.Event-circle {
  position: absolute;
  top: 250px;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: #E5ACBA; 
  box-shadow: 0px 2px 0px rgba(0,0,0,0.3);
  border-style: hidden;
  align-items: center;
  z-index: 5;
}

.Updating-icon-div {
  position: absolute;
  top: 290px;
  z-index: 5;
}
.Updating-icon {
  width: 70px;
  height: 70px;
}

@media (prefers-reduced-motion: no-preference) {
  .Updating-icon {
    animation: Updating-icon-spin infinite 3s linear;
  }
}

@keyframes Updating-icon-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Action-mark{
  height: 55px;
}

.Action-text{
  font-weight: bold;
  font-family: 'Roboto-Regular';
  font-size: 15px;
  color: #000;
}

.Action-circle-div-element{
  position: relative;
  width: 100%;
}

.Event-label{
  font-size: 36px;
  font-weight: bold;
  font-family: 'FingerPaint-Regular';
  margin-top: 30px;
  text-transform: uppercase;
  z-index: 99;
}

.Event-info-div{
  align-items: center;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  z-index: 99;
}

.Event-title{
  font-size: 24px;
  font-weight: bold;
  font-family: 'Roboto-Regular';
  line-height: 1.4;
}

.Event-description{
  font-family: 'Roboto-Regular';
  line-height: 1.6;
}

.Event-continue-button {
  font-size: 18px;
  font-family: 'Roboto-Regular';
  color: #1A79BD;
  border-width: 0px;
  background-color: transparent;
  font-weight: 500;
  z-index: 4;
}

.Dilema-question{
  margin-left: 20px;
  margin-right: 20px;  
  font-size: 18px;
  font-family: 'Roboto-Regular';
  line-height: 1.5;
  font-weight: 500;
}

.Dilema-label{
  font-size: 20px;
  font-family: 'FingerPaint-Regular';
  margin-top: 40px;
  text-transform: uppercase;
}

.Dilema-label-modal{
  font-size: 20px;
  font-family: 'FingerPaint-Regular';
  margin-top: 0px;
  text-transform: uppercase;
}


.Dilema-category{
  font-size: 30px;
  font-family: 'Roboto-Regular';
  text-transform: uppercase;
  color: #217194;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.Dilema-number{
  display: inline;
  height: 50px;
}

.Dilema-option-div{
  margin-top: 10px;
  display: inherit;
  align-items: center;
  z-index: 5;
}

.Dilema-option-text-div{
  width: 280px;
  height: 90px;
  background-color: #217194;
  color: #fff;
  padding: 10px;
  margin: 0 auto;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.Dilema-option-modal-text-div{
  width: 300px;
  height: 70px;
  background-color: #748489;
  color: #fff;
  padding: 10px;
  margin: 0 auto;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.Dilema-option-text{
  margin: 0 auto;
}

.Dilema-option-info{
  height: 40px;
  display:inline;
  margin-left: 5px;
  margin-right: 5px;
}

.Dilema-option-info-div{
  z-index: 3;
}

.Option-label{
  font-size: 18px;
  font-family: 'Roboto-Regular'; 
  text-align: left;
  margin-left: 40px;
  margin-top: 40px;
  font-weight: bold;
}

.Dilema-option-description{
  font-size: 15px;
  font-family: 'Roboto-Regular';
  padding-left: 40px;
  padding-right: 40px;
  line-height: 1.5;
  padding-top: 0px;
  margin-bottom: 100px;
}

.Game-over-label{
  font-size: 38px;
  font-family: 'FingerPaint-Regular';
  margin-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  width: 300px;
  margin-bottom: 20px;
}

.App-game-over-footer{
  z-index: 1;
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  padding-bottom: 15px;
}

.Scope-points-div{
  display: inherit;
  align-items: center;
  z-index: 5;
  padding-left: 30px;
  padding-right: 30px;
}

.Scope-points-icon{
  margin-right: 20px;
}

.Scope-points-title{
  height: 50px;
  padding: 10px;
  margin: 0 auto;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
  width: 130px;
}

.Scope-points-value{
  font-weight: bold;
  font-size: 18px;
}

.Game-over-text{
  font-size: 18px;
  font-family: 'Roboto-Regular';
  padding-left: 40px;
  padding-right: 40px;
  line-height: 1.5;
  margin-top: 30px;
  font-weight: 500;
  max-width: 500px;
}

.View-results-button {
  font-size: 18px;
  font-family: 'Roboto-Regular';
  color: #1A79BD;
  border-width: 0px;
  background-color: transparent;
  font-weight: 500;
  margin-top: 30px;
  text-decoration: none;
  margin-bottom: 50px;
}

.Game-result-text{
  font-size: 15px;
  font-family: 'Roboto-Regular';
  padding-left: 30px;
  padding-right: 30px;
  line-height: 1.5;
  margin-top: 20px;
  font-weight: 500;
  max-width: 500px;
}

.Finish-button{
  font-size: 18px;
  font-family: 'Roboto-Regular';
  color: #1A79BD;
  border-width: 0px;
  background-color: transparent;
  font-weight: 500;
  margin-top: 20px;
  text-decoration: none;
  margin-bottom: 80px;
}

.Result-view-options-div{
  margin-top: 10px;
  padding-left: 150px;
  font-weight: 400;
}

.Result-option-link{
  color: #1A79BD;
}

.Result-img{
  height: 230px;
}

.Language-select-div{
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 2;
}

.Language-select{
  font-size: 14px;
  border-style: hidden;
  border-color: #fff;
  width: 50px;
  font-family: 'Roboto-Regular';
  color: #1A79BD;
  text-align: center;
}

.Game-actions-info-icon{
  position: absolute;
  top: 10px;
  right: 10px;
  height: 32px;
  width: 32px;  
}

.Game-finish-icon{
  position: absolute;
  top: 15px;
  left: 15px;
  width: 25px;  
}

.Game-actions-control-icon{
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-left: 10px;
}

.Obtained-points-modal-body{
  width: 100%;
  padding: 20px;
}

.Obtained-points-modal-footer{
  position: relative;
  height: 30px;
}

.Innovation-text{
  color: #877C16;
}

.Society-text{
  color: #8B1772;
}

.Environment-text{
  color: #1B5F2A;
}

.Economy-text{
  color: #1B16FF;
}

.Innovation-text, .Society-text, .Environment-text, .Economy-text{
  font-weight: 700;
  width: 29px;
  display: inline-block;
  text-align: right;
  padding-right: 5px;
}

.Points-modal-icon{
  height: 25px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
